<template>
<Navbar user="0"/>
    <section class="courses-area ptb-100" v-if="!isLoading">
      <div class="container">
        <div class="courses-topbar">
          <div class="row align-items-center">
            <h2 class="text-center">All Self Tests</h2>
          </div>
        </div>
        <div  v-if="tests.length">
          <div class="shadow-lg pt-5">
            <div class="row">
              <div class="col-lg-2 col-md-2 mix business design language" v-for="test in tests" :key="test.id">
                <SingleTest :test="test" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h4 class="text-center">We have No Available Test</h4>
        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import SingleTest from '@/components/SingleTest.vue'
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
export default {
  components: { 
    Navbar, SingleTest, Loading
  },
  data(){
    return {
      isLoading: true,
      token: localStorage.getItem('user_data'),
      errors: {},
      user: {},
      tests: {}
    }
  },
  methods:{
    getSelftTest(){
      axios.get('https://apitraining.vipawaworks.com/api/self_test/get_all_self_test').then(response => {
        this.tests = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    document.title = 'Self Test - Training'
    this.getSelftTest() 
  }
}
</script>

<style>

</style>